import { useState } from 'react';
import { Box, Typography, Stack, LinearProgress, Divider } from '@mui/material';
import { useLocales } from 'src/locales';
import { CallAPI } from 'src/utils/API/APIConfig';
import { GET_FINANCIAL_INFO } from 'src/utils/API/EndPoints/HomeEp';
import FinancialIcon from 'src/assets/icons/FinancialIcon';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';

export default function FinancialPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const { translate, currentLang } = useLocales();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    refetch();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const { data: financialInfo, refetch } = CallAPI({
    name: ['financialInfo', currentLang],
    url: GET_FINANCIAL_INFO,
    enabled: true,
    select: (data: any) => data.data.data,
  });

  const total = financialInfo?.creditLimit || 0;
  const currenValue = financialInfo?.consumedCreditLimitAmount || 0;
  const progressValue =
    financialInfo?.creditLimit > 0 ? Math.round((100 / total) * currenValue) : 0;

  const isconsumedExceed = financialInfo?.consumedCreditLimitAmount > financialInfo?.creditLimit;

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: '40px', height: '40px', p: 0, alignItems: 'end' }}
      >
        <FinancialIcon />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ p: 0, mt: 3 }}>
        {/* TOP  */}
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 2,
            px: 2.5,
          }}
        >
          <Box display="flex" gap="0 10px" mb="10px">
            <Typography
              fontSize="14px"
              variant="subtitle1"
              fontWeight={400}
              color="darkBlue.light"
            >{`${translate('remaining_credit_limit')}`}</Typography>
            <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="primary">
              {`${translate('sar')} `} {financialInfo?.remainingCreditLimitAmount}
            </Typography>
          </Box>
          <Box mb="10px">
            <LinearProgress
              color={isconsumedExceed ? 'warning' : 'primary'}
              variant="determinate"
              value={progressValue}
            />
          </Box>
          <Box display="flex" gap="0 10px">
            <Typography
              fontSize="14px"
              variant="subtitle1"
              fontWeight={400}
              color="darkBlue.light"
            >{`${translate('consumed')}`}</Typography>
            <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="primary">
              {financialInfo?.consumedCreditLimitAmount} {` ${translate('of')} `}{' '}
              {financialInfo?.creditLimit}
            </Typography>
          </Box>

          {isconsumedExceed && (
            <Typography
              fontSize="12px"
              variant="subtitle1"
              fontWeight={400}
              color="#E87605"
              mt="10px"
            >{`${translate('you_exceed_the_limit')}`}</Typography>
          )}
        </Stack>
        <Divider sx={{ my: 1 }} />
        {/* CONTENT  */}
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 2,
            px: 2.5,
            minWidth: '418px',
          }}
        >
          {/* credit_limit  */}
          <Box display="flex" justifyContent="space-between" gap="0 15px" width="100%" mb="10px">
            <Typography
              fontSize="14px"
              variant="subtitle1"
              fontWeight={400}
              color="darkBlue.light"
            >{`${translate('credit_limit_company')}`}</Typography>
            <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="primary">
              {`${translate('sar')} `} {financialInfo?.creditLimit}
            </Typography>
          </Box>

          {/* total_unpaid_invoice  */}
          {financialInfo?.unpaidInvoice >= financialInfo?.creditLimit &&
          financialInfo?.unpaidInvoice !== 0 ? (
            <Box display="flex" justifyContent="space-between" gap="0 15px" width="100%" mb="10px">
              <Typography
                fontSize="14px"
                variant="subtitle1"
                fontWeight={400}
                color="#E87605"
              >{`${translate('total_unpaid_invoice')}`}</Typography>
              <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="#E87605">
                {`${translate('sar')} `} {financialInfo?.unpaidInvoice}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" gap="0 15px" width="100%" mb="10px">
              <Typography
                fontSize="14px"
                variant="subtitle1"
                fontWeight={400}
                color="darkBlue.light"
              >{`${translate('total_unpaid_invoice')}`}</Typography>
              <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="primary">
                {`${translate('sar')} `} {financialInfo?.unpaidInvoice}
              </Typography>
            </Box>
          )}

          {/* oldest_unpaid_invoice  */}
          {financialInfo?.oldestInvoiceAge > 30 ? (
            <Box display="flex" justifyContent="space-between" gap="0 15px" width="100%" mb="10px">
              <Typography
                fontSize="14px"
                variant="subtitle1"
                fontWeight={400}
                color="#E87605"
              >{`${translate('oldest_unpaid_invoice')}`}</Typography>
              <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="#E87605">
                {financialInfo?.oldestInvoiceAge} {` ${translate('days')} `}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" gap="0 15px" width="100%" mb="10px">
              <Typography
                fontSize="14px"
                variant="subtitle1"
                fontWeight={400}
                color="darkBlue.light"
              >{`${translate('oldest_unpaid_invoice')}`}</Typography>
              <Typography fontSize="14px" variant="subtitle1" fontWeight={500} color="primary">
                {financialInfo?.oldestInvoiceAge} {` ${translate('days')}`}
              </Typography>
            </Box>
          )}
        </Stack>
      </MenuPopover>
    </>
  );
}
