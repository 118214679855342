import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { CartContext } from 'src/utils/Contexts/Cart/CartProvider';
import { NotificationsContext } from 'src/utils/Contexts/Notifications/NotificationsProvider';
import useResponsive from '../../hooks/useResponsive';
import { useSettingsContext } from '../../components/settings';
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const { refetchCNotifications } = useContext(NotificationsContext);

  const location = useLocation();

  const { refetchCartCount } = useContext(CartContext);
  useEffect(() => {
    if (refetchCartCount) {
      refetchCartCount();
    }
    if (refetchCNotifications) {
      refetchCNotifications();
    }
  }, [location.key, refetchCartCount, refetchCNotifications]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        '& *:focus-visible ': {
          outline: 'none;',
        },
      }}
    >
      <>
        <Header onOpenNav={handleOpen} />
        {renderNavVertical}
      </>

      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}
