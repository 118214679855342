import {
  List,
  Typography,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Stack,
  Container,
} from '@mui/material';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';
import { fDateTimeLang } from 'src/utils/formatTime';
import EmptyContent from 'src/components/empty-content/EmptyContent';

export default function NotificationList({ notifications }: any) {
  const { currentLang, translate } = useLocales();

  const isRtl = currentLang.value === 'ar';

  return (
    <List disablePadding>
      {notifications.length ? (
        notifications?.map((notification: any) => (
          <NotificationItem key={notification.id} notification={notification} isRtl={isRtl} />
        ))
      ) : (
        <Container>
          <EmptyContent
            title={`${translate('no_notifications_yet')}`}
            description={`${translate('you_have_no_notifications')}`}
            img="/assets/icons/notification/bell.png"
            sx={{
              px: 6,
              '& img': { height: '60px' },
              'span.MuiBox-root': { mt: 1, height: '60px', mb: 2 },
            }}
          />
        </Container>
      )}
    </List>
  );
}

type NotificationItemProps = {
  id: string;
  title: string;
  titleAr: string;
  body: string;
  bodyAr: string;
  avatar: string | null;
  type: string;
  creationDate: any;
  isUnRead: boolean;
};

function NotificationItem({
  notification,
  isRtl,
}: {
  notification: NotificationItemProps;
  isRtl: boolean;
}) {
  const { currentLang } = useLocales();
  const { avatar, title } = renderContent(notification, isRtl);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-outline" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">
              {fDateTimeLang({ date: notification?.creationDate, lang: currentLang.value })}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps, isRtl: boolean) {
  const title = (
    <>
      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle2">
        {isRtl ? notification.titleAr : notification.title}
      </Typography>
      <Typography
        component="span"
        variant="body2"
        sx={{ fontSize: '12px', color: 'text.secondary' }}
      >
        {isRtl ? notification.bodyAr : notification.body}
      </Typography>
    </>
  );

  return {
    avatar: <img alt={notification.title} src="/assets/icons/notification/bell_colored.png" />,
    title,
  };
}
