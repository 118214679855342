import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import CartWidget from 'src/sections/header/CartWidget';
import { useSettingsContext } from '../../../components/settings';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { HEADER, NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import useOffSetTop from '../../../hooks/useOffSetTop';
import { bgBlur } from '../../../utils/cssStyles';
import SearchBar from '../../../sections/header/search/SearchInput';
import NotificationsPopover from './NotificationsPopover';
import FinancialPopover from './FinancialPopover';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const bodyWidth =
    document?.getElementsByTagName('body')[0]?.getBoundingClientRect()?.width || '100%';

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <div style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack sx={{ width: '100%', maxWidth: '815px' }}>
            <SearchBar />
          </Stack>

          <Stack
            flexShrink={0}
            direction="row"
            alignItems="end"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
            gap='0 10px'
          >
            <CartWidget />
            <NotificationsPopover />
            <FinancialPopover />
            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Box>
      </div>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          paddingLeft: `calc(((100% - ${bodyWidth}px ) / 2 ) + ${NAV.W_DASHBOARD - 1}px)`,
          paddingRight: `calc((100% - ${bodyWidth}px ) / 2 )`,
          backgroundColor: '#F6F7F8',
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          backgroundColor: 'white',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
