/* eslint-disable import/no-duplicates */

import { DatePicker as Picker } from '@mui/x-date-pickers';
import { useLocales } from 'src/locales';
import ar from 'date-fns/locale/ar-EG';
import en from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DatePicker = (rest: any) => {
  const { currentLang } = useLocales();
  return (
    <LocalizationProvider
      adapterLocale={currentLang.value === 'ar' ? ar : en}
      dateAdapter={AdapterDateFns}
    >
      <Picker {...rest} />
    </LocalizationProvider>
  );
};

export default DatePicker;
