import { styled } from '@mui/material/styles';


export const StyledRoot = styled('main')(() => ({
  display: 'flex',
  position: 'static',
}));

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'static',
  [theme.breakpoints.up('sm')]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const StyledSectionBg = styled('div')(({ theme }) => ({
  background:
    'linear-gradient(261.81deg, #8C4A97 -5.85%, #893497 95.6%), linear-gradient(90deg, #FDECAD 0.04%, #EC6548 50.15%, #8C4A97 100.02%)',
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
}));


export const LoginWrapper = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    maxWidth: '35%',
    padding: theme.spacing(15, 4, 0, 4),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(30, 8, 0, 8),
  }
}));
