import { ANONYMOUS_PATH_DASHBOARD,PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

const icon = (name: string,type:string='svg') => (
  <SvgColor src={`/assets/icons/navbar/${name}.${type}`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_menu_item'),
  orders: icon('ic_cart'),
  invoices: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  reports: icon('ic_analytics'),
  booking: icon('ic_booking'),
  chat: icon('ic_chat'),
  rfq: icon('ic_label'),
  tags: icon('tags','png'),
};

const navConfig = [
  {
    subheader: 'Home',
    items: [
      { title: 'Marketplace', path: ANONYMOUS_PATH_DASHBOARD.root, icon: ICONS.ecommerce,deep:false },

      {
        title: 'Categories',
        path: ANONYMOUS_PATH_DASHBOARD.categories.root,
        icon: ICONS.dashboard,
        isCategoryItem: true,
        children: [],
      },
      {
        title: 'Orders',
        path: '',
        redirect_url:PATH_DASHBOARD.Orders.root,
        icon: ICONS.orders
      },
      {
        title: 'Invoices',
        path: '',
        redirect_url: PATH_DASHBOARD.invoices.root,
        icon: ICONS.invoices
      }
    ],
  },

  {
    subheader: 'management',
    items: [
      {
        title: 'ReorderingLists',
        path: '',
        redirect_url: PATH_DASHBOARD.ReorderingLists.root,
        icon: ICONS.calendar,
      },
      {
        title: 'Reports',
        path: '',
        icon: ICONS.reports,
      },
      {
        title: 'AnalyticsTags',
        path: '',
        redirect_url: PATH_DASHBOARD.AnalyticsTagsList,
        icon: ICONS.tags,
        roles: process.env.REACT_APP_ALLOW_TAGS === 'true' ? undefined : ['tags'],
      },
    ],
  },
  {
    subheader: 'Help',
    items: [
      {
        title: 'CustomerService',
        path: 'https://wa.me/966557001599',
        icon: ICONS.chat,
      },
    ],
  },
];

export default navConfig;
