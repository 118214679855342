import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box, Badge, BadgeProps, Typography, alpha, Link } from '@mui/material';
import ic_cart from 'src/assets/icons/ic_cart.png';
import avatarSrc from 'src/assets/shared/avatar.png';
import { useLocales } from 'src/locales';
import GuestPopup from 'src/pages/auth/login/GuestLogin';
import { useSettingsContext } from '../../../components/settings';
import LanguagePopover from './LanguagePopover';
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { HEADER, NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import useOffSetTop from '../../../hooks/useOffSetTop';
import { bgBlur } from '../../../utils/cssStyles';
import GuestSearchBar from '../../../sections/header/search/GuestSearchInput';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function GuestHeader({ onOpenNav }: Props) {
  const [openGuestPopup, setOpenGuestPopup] = useState(false);
  const { currentLang, translate } = useLocales();
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const bodyWidth = document?.getElementsByTagName('body')[0]?.getBoundingClientRect()?.width || '100%';
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    marginInlineEnd: '6px',
    '& .MuiBadge-badge': {},
  }));


  const handleOpenGuestPopup = async () => {
    setOpenGuestPopup(true);
  };


  const StyledRoot = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
    cursor: 'pointer',
  }));

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <div style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between" }} >
        <Stack sx={{ width: '100%', maxWidth: "815px",mr:'10px' }}>
        <GuestSearchBar />
          </Stack>
          <Stack
            flexShrink={0}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <LanguagePopover />
            <a onClick={handleOpenGuestPopup} href='#'>
              <StyledBadge badgeContent='0' showZero color="primary">
                <Box component="img" src={ic_cart} sx={{ width: 35, height: 35 }} />
              </StyledBadge>
            </a>
            <Link onClick={handleOpenGuestPopup} href='#'>
              <StyledRoot>

                <Box
                  component="img"
                  src={avatarSrc}
                  sx={{ borderRadius: '50%', width: 40, height: 40, marginInlineEnd:1 , ml:'2px' }}
                />
                <Typography variant="subtitle1" sx={{color: 'text.secondary', textDecoration:'none'}}>
                {` ${translate('auth.login')}`}
                </Typography>
              </StyledRoot>
            </Link>
          </Stack>
        </Box>
        {openGuestPopup &&
          <GuestPopup open={openGuestPopup} onClose={() => setOpenGuestPopup(false)} />
         }
      </div>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          paddingLeft: `calc(((100% - ${bodyWidth}px ) / 2 ) + ${NAV.W_DASHBOARD - 1}px)`,
          paddingRight: `calc((100% - ${bodyWidth}px ) / 2 )`,
          backgroundColor: '#F6F7F8',
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          backgroundColor: 'white'
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
