import { useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';
import { CallAPI } from 'src/utils/API/APIConfig';
import avatarSrc from 'src/assets/shared/avatar.png';
import { useLocales } from '../../../locales';
import Companies from './Companies';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount() {
  const { translate } = useLocales();
  const { username, setUsername, setUserInfo, isAuthenticated, fillUserProviderFromLocalStorage } =
    useContext(UserContext);

  const { isFetching: fetchProfile } = CallAPI({
    name: 'getProfileInfo',
    url: '/Account/profile',
    baseURL: `${process.env.REACT_APP_DOMAIN}`,

    refetchOnWindowFocus: false,
    enabled: true,
    method: 'get',
    select: (data) => data?.data?.data,
    onSuccess: (res) => {
      if (!isAuthenticated()) {
        fillUserProviderFromLocalStorage();
      }
      setUsername(`${res?.firstName}`);
      if (res) {
        setUserInfo(res);
      }
    },
  });

  return (
    <StyledRoot>
      <Box
        component="img"
        src={avatarSrc}
        sx={{ borderRadius: '50%', width: 40, height: 40, cursor: 'pointer' }}
      />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {username}
        </Typography>

        <Companies translate={translate} />
      </Box>
    </StyledRoot>
  );
}
