export const CATEGORIES_LOOK_UP = "Categories/Category-lookup";
export const SUB_CATEGORIES_LOOK_UP = "/Categories/get-sub-Category";
export const PRODUCTS_LIST = "Products/get-product-list";
export const PRODUCTS_LIST_V2 = "Products/get-product-list-v2";
export const REORDERING_LIST = "ProductList/get-product-list-list";
export const ADD_FAVOURITE = "FavouriteProduct/add";
export const REMOVE_FAVOURITE = "FavouriteProduct/remove";
export const ACCOUNT_PROFILE = "Account/profile";
export const GET_BANNER = 'Banner/get-banners';
export const GET_PRODUCT_CHART = '/Charts/products-invoicing-report';
export const GET_CUSTOMER_CHART = '/Charts/customer-spending-report';
export const GET_ADDRESS_URL = "Company/get-address";
export const ADD_ADDRESS_URL = "company/add-address";
export const DELET_TAGS_URL = "/OrderTag/deactivate-tag";
export const CART_ITEMS_URL = "Cart/get-cart-items";
export const UPDATE_CART_ITEM_URL = "Cart/add-to-cart";
export const DELETE_CART_ITEM_URL = "Cart/Delete-cart-items";
export const ORDER_PRODUCTS_AGAIN_URL = "Cart/Add-RFQ-to-cart";
export const COVERT_RFQ_TO_ORDER = "RFQ/convert-rfq-to-order";
export const RENEW_RFQ = "RFQ/renew-RFQ";
export const VALIDATE_CART_ITEMS_URL = "Cart/validate-cart-products";
export const CONFIRM_ORDER_URL = "OrderPayment/add-order";
export const CONFIRM_PromoCode = "PromoCode/validate";
export const REPAY_ORDER_URL = "OrderPayment/Repay";
export const GET_ORDERS_URL = "Order/get-orders";
export const GET_ORDER_DETAILS_URL = "Order/get-orders";
export const GET_INVOICES_URL = "Order/get-invoices-by-order";
export const CHECK_MAIN_DATA_EXIST = "Company/check-registration-data";
export const UPDATE_REMAINING_REGISTRATION_DATA = "Company/update-remaining-registration-data";
export const PRINT_ORDER_DETAILS_URL = "/Order/get-order-pdf";
export const PRINT_INVOICES_DETAILS_URL = "/Order/get-invoice-pdf";
export const PRINT_NOTE_DETAILS_URL = "/Order/get-delivery-note-pdf";
export const GENERATE_CHECKOUT_TOKEN_URL = "HyperPay/generate-merchant-token";
export const EDIT_ADDRESS_URL = "company/edit-address";
export const GET_USER_CARDS_URL = "HyperPay/get-user-cards";
export const ADD_NEW_PAYMENT_URL = "HyperPay/add-new-card";
export const Delete_ADDRESS_URL = "Company/delete-address";
export const Delete_USER_Card = "HyperPay/delete-card";
export const GET_ANNOUNCEMENT = "Announcement/get-announcements";

export const GET_RFQ_DETAILS_URL = "RFQ/get-RFQ-by-id";
export const GET_RFQ_URL = "RFQ/get-company-RFQ";
export const CREATE_RFQ_URL = "RFQ/add-RFQ";
export const GET_RFQ_COMMENTS = "RFQ/get-rfq-comment";
export const SEND_RFQ_COMMENTS = "RFQ/send-RFQ-comment";

export const GET_STARTER_PACK = "StarterPacks/get-starter-packs";
export const GET_INVOICES_BY_COMPANY = "Order/get-invoices-by-company";

export const GET_AGREEMENT_CONTRACTS_URL = "Company/get-company-contracts";

//   Anonymous api
export const GET_Anonymous_BANNER = 'Anonymous/get-banners';
export const Anonymous_CATEGORIES_LOOK_UP = "Anonymous/Category-lookup";
export const Anonymous_PRODUCTS_LIST = "Anonymous/get-product-list";
export const Anonymous_SUB_CATEGORIES_LOOK_UP = "Anonymous/get-sub-Category";
export const Anonymous_GET_STARTER_PACK = "Anonymous/get-starter-packs";
// end of Anonymous api

export const GetDeliveryNote = "DeliveryNote/get-delivery-note";
export const SignDeliveryNote = "DeliveryNote/sign-delivery-note";
export const GetOrderTagList = "OrderTag/get-tag-list";
export const AddNewOrderTag = "OrderTag/add-tag";
export const UpdateOrderTag = "OrderTag/update-order-tag";

export const GetDeliveryNoteFiles = "DeliveryNote/get-delivery-note-files";
export const DownloadDeliverySignatureFile = "DeliveryNote/download-delivery-note-file";

export const GET_MY_CATALOG_LIST = "products/get-company-product-list";
export const Invite_Colleague = "Users/invite-colleague";

export const GET_FINANCIAL_INFO = "company/get-company-financial-info";
export const GET_SUBSCRIPTION_MANAGEMENT = "subscription/get-subscription";
export const CREATE_SUBSCRIPTION_MANAGEMENT = "subscription/add-subscription";
export const GET_SUBSCRIPTION_STATUS = "subscription/get-subscription-status";
export const GET_SUBSCRIPTION_TYPE = "subscription/get-subscription-type";
export const GET_SUBSCRIPTION_CARD = "subscription/get-subscription-cards";
