import { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CallAPI } from 'src/utils/API/APIConfig';
import { GET_ANNOUNCEMENT } from 'src/utils/API/EndPoints/HomeEp';

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const { refetch: refetchCNotifications } =
  CallAPI({
    name: 'getAnnouncement',
    url: GET_ANNOUNCEMENT,
    refetchOnWindowFocus: true,
    enabled: true,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      setNotifications(res);
    },
  });

  const memoizedValue = useMemo(
    () => ({notifications,refetchCNotifications }),
    [notifications,refetchCNotifications]
  );
  return (
    <NotificationsContext.Provider value={memoizedValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationsProvider;
