
import { SVGProps } from "react"

const FinancialIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#637381"
      d="M19.91 12.372a7.742 7.742 0 1 0 7.741 7.742 7.75 7.75 0 0 0-7.742-7.742Zm2.516 4.885h-2.517a.884.884 0 1 0 0 1.768h.025a2.275 2.275 0 0 1 2.272 2.272v.222a2.276 2.276 0 0 1-1.602 2.17v1.388h-1.389v-1.284H17.48v-1.389h2.45a.885.885 0 0 0 .884-.884v-.222a.885.885 0 0 0-.884-.883h-.024a2.273 2.273 0 0 1-.694-4.438v-1.294H20.6v1.185h1.823l.003 1.389Z"
    />
    <path
      fill="#637381"
      d="M19.908 8.93a11.185 11.185 0 1 0-.006 22.37 11.185 11.185 0 0 0 .006-22.37Zm0 20.314a9.131 9.131 0 1 1 9.127-9.13 9.14 9.14 0 0 1-9.127 9.13Z"
    />
    <path
      fill="#637381"
      d="M32.853 32.86a18.293 18.293 0 0 0 3.979-19.418C33.179 4.052 22.566-.615 13.176 3.039l.566 1.457c8.592-3.342 18.293.925 21.635 9.512a16.729 16.729 0 0 1-3.645 17.762l-1.11-1.082a.825.825 0 0 0-1.402.603l.041 2.94a.825.825 0 0 0 .835.814l2.941-.035a.825.825 0 0 0 .566-1.416l-.75-.733ZM13.56 35.188A16.7 16.7 0 0 1 8.355 8.199l.984.955a.825.825 0 0 0 1.402-.602l-.042-2.941a.824.824 0 0 0-.835-.814l-2.94.035a.825.825 0 0 0-.57 1.422l.88.856A18.27 18.27 0 0 0 26.91 36.93l-.566-1.456a16.6 16.6 0 0 1-12.784-.286Z"
    />
  </svg>
)
export default FinancialIcon
