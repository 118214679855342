import { Button, Stack, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import MobileSvg from 'src/assets/illustrations/MobileView';
import Logo from 'src/assets/illustrations/Logo';
import Iconify from '../iconify';

const MobileView = () => {
  const { translate } = useLocales();
  const IS_APP_STORE = 'appStore';

  const renderBtnContent = (type?: string) => (
    <Stack direction="column">
      <Typography
        variant="subtitle2"
        sx={{
          color: '#003648',
          fontSize: '14px',
          marginBottom: 0,
          fontFamily: 'SomarSans',
          fontWeight: 400,
        }}
      >
        Available on the
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: '#4545DB',
          fontSize: '16px',
          marginBottom: 0,
          fontWeight: 700,
          fontFamily: 'SomarSans',
        }}
      >
        {type === 'appStore' ? 'App Store' : 'Google Play'}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction="column" alignItems="center">
      <Stack mt="20px" mb="50px" display="flex" flexDirection="column-reverse" gap="70px">
        <MobileSvg />
        <Logo />
      </Stack>
      <Typography
        textAlign="center"
        mb="55px"
        sx={{ fontSize: '16px', fontWeight: '500' }}
        variant="h6"
      >{`${translate('mobile_redirection_message')}`}</Typography>
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            marginBottom: '24px',
            color: '#003648',
            fontSize: '24px',
            fontWeight: '700',
          }}
          variant="h5"
        >{`${translate('download_our_app')}`}</Typography>
        <Button
          href="https://play.google.com/store/apps/details?id=com.lawazem.customerapp&pli=1"
          variant="outlined"
          startIcon={<Iconify icon="simple-icons:googleplay" width={26} color="#4545DB" />}
          sx={{
            justifyContent: 'space-between',
            borderRadius: '10px',
            border: '2px solid #4545DB',
            paddingRight: '30px',
            paddingLeft: '30px',
            textTransform: 'capitalize',
            width: '250px',
            marginBottom: '16px',
            height: '48px'
          }}
        >
          {renderBtnContent()}
        </Button>
        <Button
          href="https://apps.apple.com/eg/app/lawazem-%D9%84%D9%88%D8%A7%D8%B2%D9%85/id6444859755"
          variant="outlined"
          startIcon={<Iconify icon="ic:baseline-apple" width={36} color="#4545DB" />}
          sx={{
            justifyContent: 'space-between',
            borderRadius: '10px',
            border: '2px solid #4545DB',
            paddingRight: '30px',
            paddingLeft: '30px',
            textTransform: 'capitalize',
            width: '250px',
            height: '48px'
          }}
        >
          {renderBtnContent(IS_APP_STORE)}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MobileView;
