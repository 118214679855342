function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_DASHBOARD = '/';

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  otp: '/otp',
  choose_company: '/choose_company',
};

export const PATH_DASHBOARD = {
  root: '/',
  SearchResults: { root: (searchValue: any) => `/search/${searchValue}` },
  MarketPlace: {
    root: '/',
    view: (name: string) => `/products/${name}`,
  },
  Controls: '/Controls',
  Orders: {
    root: '/orders',
    view: (id: string) => path('/orders', `/${id}`),
  },
  categories: {
    root: '/categories',
    sections: '/categories/section',
    CategoriesProducts: '/Categories/Products.Category',
    EssentialsPack: '/categories/EssentialsPack',
    Store: '/store',
  },
  reports: '/reports',
  invoices: {
    root: '/invoices',
    view: (id: string) => path('/invoices', `/${id}`),
    payment: (id: string) => path('/invoices/payment', `/${id}`),
  },
  ReorderingLists: {
    root: '/ReorderingLists',
    view: (id: string) => path('/ReorderingLists', `/${id}`),
  },
  UserProfile: '/UserProfile',
  CompanyTabs: '/CompanyProfile',
  CustomerService: '/CustomerService',
  CartDetails: '/cart',
  ConfirmationOrderScreen: '/order_confirmation',
  PrivacyPolicy: '/privacypolicy',
  ProductNotFound: '/productnotfound',
  Notifications: '/notifications',

  Accessdenied: '/Accessdenied',
  Catalog: '/catalog',
  RFQ: {
    root: '/request_for_quotation',
    RFQForm: '/request_for_quotation/create',
    view: (id: string) => path('/request_for_quotation', `/${id}`),
    payment: (id: string) => path('/request_for_quotation/payment', `/${id}`),
  },
  AnalyticsTagsList: '/AnalyticsTagsList',
  MyCatalog: {
    root: '/catalog',
  },
  SubscriptionManagement: '/subscription-management'
};

export const ANONYMOUS_PATH_DASHBOARD = {
  root: '/',
  SearchResults: { root: (searchValue: any) => `/search/${searchValue}` },
  MarketPlace: {
    root: '/',
    view: (name: string) => `/products/${name}`,
  },
  categories: {
    root: '/categories',
    sections: '/categories/section',
    CategoriesProducts: '/Categories/Products.Category',
    EssentialsPack: '/categories/EssentialsPack',
    Store: '/store',
  },
  ProductNotFound: '/productnotfound',
};
