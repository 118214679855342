// @ts-nocheck
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Stack } from '@mui/material';
import { useLocales } from 'src/locales';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFTextField } from 'src/components/hook-form';
import { CallAPI, CallMutation } from 'src/utils/API/APIConfig';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { getBrowserInfo } from 'src/utils/commonFunction';

export default function AddCompany({ open, handleClose, company, callCampanies, reftchReorderList = () => { } }) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();
  const CompanySchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(translate('form_validation.mandatory'))
        .min(3, translate('form_validation.between_03_50_format'))
        .max(50, translate('form_validation.between_03_50_format'))
        .transform((value) => value.trim()),
      taxId: Yup.string()
        .max(15, translate('form_validation.accept_15_characters'))
        .matches(/^[0-9]{0,15}$/, translate('form_validation.please_enter_numbers_only'))
        .transform((value) => value.trim()),
      registrationNumber: Yup.string()
        .max(15, translate('form_validation.accept_15_characters'))
        .matches(/^[0-9]{0,15}$/, translate('form_validation.please_enter_numbers_only'))
        .transform((value) => value.trim()),
    });

  const defaultValues = {
    name: company?.name || '',
    taxId: company?.taxId || '',
    registrationNumber: company?.registrationNumber || '',
  };

  const methods = useForm<any>({
    resolver: yupResolver(CompanySchema()),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (company) {
      reset(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const { data: profileInfo } = CallAPI({
    name: 'getProfileInfo',
    url: '/Account/profile',
    select: (res) => res.data.data,
  });

  const AddNewCompany = CallMutation({
    url: "/company/add-another-company",
    baseURL: process.env.REACT_APP_AUTH,
    onSuccess: (res) => {
      if (res.data.success) {
        enqueueSnackbar(`${translate('added_successfully')}`);
        handleClose();
        callCampanies();
      } else {
        setErrorMsg(res.data.message);
      }
    },
  });

  const onSubmit = (data) => {
    AddNewCompany.mutate({
      ...data,
      mobileNumber: null,
      deviceType: getBrowserInfo()
    });
  };

  const { refetch: getIsValidList } = CallAPI({
    name: 'checkNameValidForAddNew',
    url: 'ProductList/is-product-list-name-valid',
    refetchOnWindowFocus: false,
    query: {
      model: watch('name'),
    },
    select: (data) => data?.data?.data,
    onSuccess: (res) => {
      if (res) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        AddNewList.mutate({
          name: watch('name'),
          description: watch('description'),
          productListTypeId: 2,
          userId: profileInfo?.id,
          companyId: profileInfo?.companyId,
        });
      } else {
        setError('name', { message: translate('form.name_validation_unique') });
      }
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(reset)} fullWidth>
        <DialogTitle>
          {company ? translate('CompanyDetails') : translate('Add_new_company')}
        </DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack my={2} spacing={2.5}>
              <RHFTextField name="name" label={translate('companyname')} />
              <RHFTextField name="taxId" label={translate('VatID')} />
              <RHFTextField name="registrationNumber" label={translate('Commercialregistrationnumber')} />

            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              size='large'

              variant="contained" color="warning" onClick={() => handleClose(reset)}>{`${translate('form.cancel')}`}</Button>
            <LoadingButton
              variant="contained"
              size='large'
              type="submit"
              loading={isSubmitSuccessful || isSubmitting}
              disabled={!watch('name')?.length}
            >{`${translate('Add_company')}`}</LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
