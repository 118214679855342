import { Navigate, Routes, Route } from 'react-router-dom';
import { Suspense, ElementType } from 'react';
import CartProvider from 'src/utils/Contexts/Cart/CartProvider';
import NotificationsProvider from 'src/utils/Contexts/Notifications/NotificationsProvider';
import CategoriesProvider from 'src/utils/Contexts/Categories/CategoriesProvider';
import GuestCategoriesProvider from 'src/utils/Contexts/Categories/GuestCategoriesProvider';
import { useAuthContext } from 'src/auth/useAuthContext';
import { isValidToken } from 'src/auth/utils';
import { checkRefreshTokenExpirationValid } from 'src/utils/API/Helpers';
import LoadingScreen from '../components/loading-screen';

import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import GuestLayout from '../layouts/dashboard/GuestLayout';
import {
  Page404,
  MarketPlace,
  LoginPage,
  Orders,
  OrderDetails,
  RegisterPage,
  MobileVerificationPage,
  Invoices,
  Reports,
  ReorderingLists,
  UserProfile,
  CompanyTabs,
  MarketPlaceProductDetailsPage,
  ChooseCompany,
  GeneralSearchList,
  ListItemDetails,
  CartDetails,
  ConfirmationOrderScreen,
  PrivacyPolicy,
  ProductNotFound,
  Notifications,
  Catalog,
  RFQList,
  RFQForm,
  RFQDetails,
  RFQPayment,
  AccessDenied,
  EssentialsPack,
  ProductsCategory,
  ProductsSection,
  InvoicePayment,
  InvoicesDetails,
  GuestMarketPlace,
  GuestProductDetailsPage,
  GuestProductsCategory,
  GuestEssentialsPack,
  GuestProductsSection,
  GuestGeneralSearchList,
  DevliveryNoteSigning,
  AnalyticsTagsList,
  DevliveryNoteDownload,
  MyCatalog,
  ProductsStore,
  GuestProductsStore,
  SubscriptionManagement
} from './elements';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const IsAuth=()=>{
  const { changeCompanyRequired } = useAuthContext();
  const accessToken=localStorage?.getItem('accessToken');
  return accessToken && (isValidToken(accessToken|| '') 
|| (checkRefreshTokenExpirationValid()===true) )&& !changeCompanyRequired;;
}
export const PrivateRoutes = () => {
  const auth = IsAuth();
  
  const allowGuestMode = process.env.REACT_APP_ALLOW_GUEST_MODE === 'true';

  if (auth) {
    return (
      <AuthGuard>
        <CartProvider>
          <NotificationsProvider>
            <CategoriesProvider>
              <DashboardLayout />
            </CategoriesProvider>
          </NotificationsProvider>
        </CartProvider>
      </AuthGuard>
    );
  }
  if (allowGuestMode) {
    return (
      <GuestGuard>
        <GuestCategoriesProvider>
          <GuestLayout />
        </GuestCategoriesProvider>
      </GuestGuard>
    );
  }
  return <Navigate to="/login" />;
};

export default function Router() {
  const auth = IsAuth();
  return (
    <Routes>
      <Route element={<PrivateRoutes />} >

        <Route element={auth ? <MarketPlace /> : <GuestMarketPlace />} path='/' />

        <Route element={<Navigate to='/' />} path='marketplace' />
        <Route element={auth ? <MarketPlaceProductDetailsPage /> : <GuestProductDetailsPage />} path='products/:sku' />
        <Route element={auth ? <GeneralSearchList /> : <GuestGeneralSearchList />} path='search/:results' />
        <Route element={auth ? <ProductsCategory /> : <GuestProductsCategory />} path='categories/:id' />
        <Route element={auth ? <ProductsSection /> : <GuestProductsSection />} path='categories/section/:id' />
        <Route element={auth ? <ProductsStore /> : <GuestProductsStore />} path='store/:id' />
        <Route element={auth ? <EssentialsPack /> : <GuestEssentialsPack />} path='categories/EssentialsPack' />
        <Route element={<ProductNotFound />} path='/productnotfound' />
        <Route element={<AccessDenied />} path='Accessdenied' />
        <Route path='invoices' >
          <Route element={auth ? <Invoices /> : <Navigate to='/' />} index />
          <Route element={auth ? <InvoicePayment /> : <Navigate to='/' />} path='payment/:id' />
          <Route element={auth ? <InvoicesDetails /> : <Navigate to='/' />} path=':id' />
        </Route>
        <Route element={auth ? <Reports /> : <Navigate to="/" />} path="reports" />
        <Route element={auth ? <MyCatalog /> : <Navigate to="/" />} path="catalog" />
        <Route element={auth ? <SubscriptionManagement /> : <Navigate to="/" />} path="subscription-management" />

        <Route path="orders">
          <Route element={auth ? <Orders /> : <Navigate to="/" />} index />
          <Route element={auth ? <OrderDetails /> : <Navigate to="/" />} path=":id" />
        </Route>

        <Route path="reorderingLists">
          <Route element={auth ? <ReorderingLists /> : <Navigate to="/" />} index />
          <Route element={auth ? <ListItemDetails /> : <Navigate to="/" />} path=":id" />
        </Route>
        <Route
          element={auth ? <AnalyticsTagsList /> : <Navigate to="/" />}
          path="AnalyticsTagsList"
        />

        <Route element={auth ? <UserProfile /> : <Navigate to="/" />} path="userprofile" />
        <Route element={auth ? <CompanyTabs /> : <Navigate to="/" />} path="companyprofile" />
        <Route element={auth ? <CartDetails /> : <Navigate to="/" />} path="cart" />
        <Route
          element={auth ? <ConfirmationOrderScreen /> : <Navigate to="/" />}
          path="order_confirmation"
        />
        <Route element={auth ? <Notifications /> : <Navigate to="/" />} path="notifications" />
        <Route path="request_for_quotation">
          <Route element={auth ? <RFQList /> : <Navigate to="/" />} index />
          <Route element={auth ? <RFQDetails /> : <Navigate to="/" />} path=":id" />
          <Route element={auth ? <RFQForm /> : <Navigate to="/" />} path="create" />
          <Route element={auth ? <RFQPayment /> : <Navigate to="/" />} path="payment/:id" />
        </Route>
        <Route element={<Navigate to="/404" replace />} path="*" />
      </Route>
      <Route
        element={
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        }
        path="/login"
      />
      <Route
        element={
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        }
        path="/register"
      />

      <Route element={<DevliveryNoteSigning />} path="/ns" />
      <Route element={<DevliveryNoteDownload />} path="/ds" />
      <Route element={<CompactLayout />}>
        <Route element={<Page404 />} path="/404" />
        <Route
          element={
            <GuestGuard>
              <MobileVerificationPage />
            </GuestGuard>
          }
          path="otp"
        />
        <Route element={<PrivacyPolicy />} path="privacy-policy" />
        <Route element={<ChooseCompany />} path="choose_company" />
      </Route>

      <Route element={<Catalog />} path="catalog" />

      <Route element={<Navigate to="/404" replace />} path="*" />
    </Routes>
  );
}
