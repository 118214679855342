import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loading-screen';

const lazyRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazyRetry(() => import('../pages/auth/login')));
export const RegisterPage = Loadable(lazyRetry(() => import('../pages/auth/register')));
export const MobileVerificationPage = Loadable(
  lazyRetry(() => import('../pages/auth/mobile-verification'))
);

export const GeneralSearchList = Loadable(
  lazyRetry(() => import('../sections/header/search/SearchList'))
);

export const MarketPlace = Loadable(
  lazyRetry(() => import('../pages/home/marketplace/Marketplace'))
);
export const MarketPlaceProductDetailsPage = Loadable(
  lazyRetry(() => import('../pages/home/Products/DetailsPage'))
);
export const Orders = Loadable(lazyRetry(() => import('../pages/home/orders/OrdersListPage')));
export const OrderDetails = Loadable(
  lazyRetry(() => import('../pages/home/orders/details/OrderDetails'))
);
export const Page404 = Loadable(lazyRetry(() => import('../pages/Page404 ')));
export const Invoices = Loadable(lazyRetry(() => import('../pages/home/invoices/ListPage')));
export const Reports = Loadable(lazyRetry(() => import('../pages/home/reports/Reports')));
export const ReorderingLists = Loadable(
  lazyRetry(() => import('../pages/management/reorder_list/ReorderingLists'))
);
export const ListItemDetails = Loadable(
  lazyRetry(() => import('../pages/management/reorder_list/reorder_list_details/ListDetails'))
);
export const UserProfile = Loadable(
  lazyRetry(() => import('../pages/account/UserProfile/UserProfile'))
);
export const CompanyTabs = Loadable(lazyRetry(() => import('../pages/CompanyProfile/CompanyTabs')));
export const Controls = Loadable(lazyRetry(() => import('../pages/Controls')));
export const ChooseCompany = Loadable(lazyRetry(() => import('../pages/auth/choose-company')));
export const CartDetails = Loadable(lazyRetry(() => import('../pages/cart')));
export const ConfirmationOrderScreen = Loadable(
  lazyRetry(() => import('../pages/cart/ConfirmationOrder'))
);
export const PrivacyPolicy = Loadable(lazyRetry(() => import('../pages/PrivacyPolicy')));

export const ProductNotFound = Loadable(
  lazyRetry(() => import('../pages/home/Products/ProductNotFound'))
);
export const Catalog = Loadable(lazyRetry(() => import('../pages/Catalog')));
export const RFQList = Loadable(lazyRetry(() => import('../pages/requestForQuotation/RFQ')));
export const RFQForm = Loadable(lazyRetry(() => import('../pages/requestForQuotation/RFQ_form')));
export const AccessDenied = Loadable(
  lazyRetry(() => import('../pages/requestForQuotation/AccessDenied'))
);
export const RFQDetails = Loadable(
  lazyRetry(() => import('../pages/requestForQuotation/RFQDetails'))
);
export const RFQPayment = Loadable(
  lazyRetry(() => import('../pages/requestForQuotation/RFQDetails/RFQPaymentSection'))
);

export const Notifications = Loadable(
  lazyRetry(() => import('../pages/Notification/Notification'))
);
export const EssentialsPack = Loadable(lazyRetry(() => import('../pages/EssentialsPack')));
export const ProductsCategory = Loadable(lazyRetry(() => import('../pages/Categories/Products.Category')));
export const ProductsSection = Loadable(lazyRetry(() => import('../pages/Categories/Products.Section')));
export const ProductsStore = Loadable(lazyRetry(() => import('../pages/Categories/Products.Store')));

export const InvoicePayment = Loadable(
  lazyRetry(() => import('../pages/home/invoices/invoicePayment'))
);
export const InvoicesDetails = Loadable(
  lazyRetry(() => import('../pages/home/invoices/details/InvoicesDetails'))
);
export const AnalyticsTagsList = Loadable(
  lazyRetry(() => import('../pages/management/analyticsTags/AnalyticsTagsList'))
);

export const GuestMarketPlace = Loadable(lazyRetry(() => import('../pages/home/marketplace/Guest/GuestMarketplace')));
export const GuestProductDetailsPage = Loadable(lazyRetry(() => import('../pages/home/ProductsGuest/GuestDetailsPage')));
export const GuestProductsCategory = Loadable(lazyRetry(() => import('../pages/Categories/GuestProducts.Category')));
export const GuestEssentialsPack = Loadable(lazyRetry(() => import('../pages/EssentialsPack/GuestEssentialsPack')));
export const GuestProductsSection = Loadable(lazyRetry(() => import('../pages/Categories/GuestProducts.Section')));
export const GuestProductsStore = Loadable(lazyRetry(() => import('../pages/Categories/GuestProducts.Store')));
export const GuestGeneralSearchList = Loadable(lazyRetry(() => import('../sections/header/search/GuestSearchList')));
export const DevliveryNoteSigning = Loadable(lazyRetry(() => import('../pages/DeliveryNoteSigning/DeliveryNoteSigning')));
export const DevliveryNoteDownload = Loadable(lazyRetry(() => import('../pages/DeliveryNoteSigning/DeliveryNoteDownload/DeliveryNoteDownload')));

export const MyCatalog = Loadable(lazyRetry(() => import('../pages/MyCatalog')));
export const SubscriptionManagement = Loadable(lazyRetry(() => import('../pages/subscription-management')));
