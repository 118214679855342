import { useContext, useState } from 'react';
import { Box, Badge, Button, Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import NotificationList from 'src/pages/Notification/NotificationList';
import { NotificationsContext } from 'src/utils/Contexts/Notifications/NotificationsProvider';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const { notifications } = useContext(NotificationsContext);

  const { translate } = useLocales();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: '40px', height: '40px', p: 0, alignItems: 'end' }}
      >
        <Badge badgeContent={notifications?.length} showZero color="warning">
          <Iconify icon="eva:bell-fill" sx={{ width: '27px', height: '27px' }} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ p: 0, mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5, height: '72px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" fontWeight={700}>{`${translate(
              'notifications'
            )}`}</Typography>
          </Box>
        </Box>

        <Divider sx={{ mb: 1 }} />
        <NotificationList notifications={notifications?.slice(0, 4)} />

        {notifications?.length > 0 && (
          <>
            <Divider />

            <Box sx={{ p: 1 }}>
              <Button
                fullWidth
                disableRipple
                component={RouterLink}
                onClick={() => handleClosePopover()}
                to={PATH_DASHBOARD.Notifications}
                sx={{ fontSize: '12px' }}
              >
                {`${translate('view_all')}`}
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}
