import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles'
// locales
import GuestPopup from 'src/pages/auth/login/GuestLogin';
import { useLocales } from '../../../../locales';
// auth
import GuestBasedGuard from '../../../../auth/GuestBasedGuard';
//
import { NavItemProps } from '../../types';
import { StyledItem, StyledIcon, StyledDotIcon } from '../styles';
import { RightIcon } from './Icon';

// ----------------------------------------------------------------------


export default function GuestNavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, info, children, disabled, caption, roles, redirect_url } = item;
  const anonymous = !path;
  const [openGuestPopup, setOpenGuestPopup] = useState(false);

  const subItem = depth !== 1;
  const theme = useTheme();

  const isRTL = theme.direction === 'rtl';
  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${translate(title)}`}
        secondary={
          caption && (
            <Tooltip title={`${translate(caption)}`} placement="top-start">
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (

        <RightIcon icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} isRTL={isRTL} />

      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      anonymous ? (
        <>
          <Link component={RouterLink} to='#' underline="none" onClick={() => setOpenGuestPopup(true)}>
            {renderContent}
          </Link>
         {openGuestPopup &&
          <GuestPopup redirect_url={redirect_url} open={openGuestPopup} onClose={() => setOpenGuestPopup(false)} />
         }
        </>
      )
        :
        (<Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>)
    );
  };

  return <GuestBasedGuard roles={roles}> {renderItem()} </GuestBasedGuard>;
}
