/* eslint-disable import/no-duplicates */
// @ts-nocheck
import { format, getTime, formatDistanceToNow } from 'date-fns';
import { enUS, ar } from 'date-fns/locale';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string, lang = 'en') {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm, { locale: lang === 'en' ? enUS : ar }) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTimeLang({ date, newFormat = null, lang = 'en' } = {}) {
  const fm = newFormat || 'dd MMM yyyy hh:mm a';

  return date ? format(new Date(date), fm, { locale: lang === 'en' ? enUS : ar }) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fMonthYear(date: InputValue) {
  const fm = 'MM/yyyy';
  return date ? format(new Date(date), fm) : '';
}
