/* eslint-disable react/prop-types */
/* eslint react/prop-types: 0 */
// eslint-disable-next-line react/jsx-no-constructed-context-values
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TokenService, checkGapBetweenTwoDate } from 'src/utils/API/Helpers';

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [User, setUser] = useState({
    access_token: TokenService.getLocalAccessToken,
    refresh_token: TokenService.getLocalRefreshToken,
  });
  const [UserInfo, setUserInfo] = useState({});
  const [currentCumpanyInfo, setCurrentCumpanyInfo] = useState({});

  const setLogin = useCallback(
    ({ accessToken, refreshToken, reFreshTokenExpiration }) => {
      setUser({
        ...User,
        access_token: accessToken,
        refresh_token: refreshToken,
        client_token: null,
      });
      // Update Refresh Token In LocalStorage

      TokenService.updateLocalAccessToken(accessToken);
      TokenService.updateReFreshTokenExpiration(reFreshTokenExpiration);
      TokenService.updateLocalRefreshToken(refreshToken);
      TokenService.updateExpirationDate(new Date());
      TokenService.removeLocalClientToken();
    },
    [User]
  );

  const fillUserProviderFromLocalStorage = useCallback(
    () => {
      setUser({
        ...User,
        access_token: TokenService.getLocalAccessToken,
        refresh_token: TokenService.getLocalRefreshToken,
      });
    },
    [User]
  );


  const isAuthenticated = useCallback(() => !!User.access_token, [User.access_token]);
  const setUserClientToken = useCallback((client_token) => setUser({
    ...User,
    client_token
  }), [User]);
  const memoizedValue = useMemo(
    () => ({
      User,
      setUser,
      setLogin,
      isAuthenticated,
      setUsername,
      username,
      UserInfo,
      setUserInfo,
      setCurrentCumpanyInfo,
      currentCumpanyInfo,
      setUserClientToken,
      fillUserProviderFromLocalStorage
    }),
    [User, isAuthenticated, setLogin, username, UserInfo, setUserClientToken, currentCumpanyInfo, fillUserProviderFromLocalStorage]
  );
  return (
    <UserContext.Provider value={memoizedValue}>
      {React.Children.only(children)}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserProvider;
